<template>
  <base-layout
    :layout-desktop-component="layout.desktop"
    :layout-mobile-component="layout.mobile"
  >
    <slot />
  </base-layout>
</template>

<script>
import { defineAsyncComponent, shallowReadonly } from "@vue/runtime-core";

import BaseLayout from "@/layouts/BaseLayout.vue";

export default {
  components: { BaseLayout },

  setup() {
    const layout = shallowReadonly({
      desktop: defineAsyncComponent({
        loader: () => import(`@/layouts/LayoutCreateDesktop.vue`),
        delay: 0,
      }),
      mobile: defineAsyncComponent({
        loader: () => import(`@/layouts/LayoutDefaultMobile.vue`),
        delay: 0,
      }),
    });

    return {
      layout,
    };
  },
};
</script>
